<template>
    <div class="referrals container">
        <div ref="swiper" class="swiper" :class="condition.isMobile ? 'row-stat ' : ''">
            <div class="referrals-wrap " :class="condition.isMobile ? 'swiper-wrapper' : 'row'">
                <div class="swiper-slide info-stat col-lg-6" shadow="never">
                    <div class="">
                        <div class="super-label-box" style="display: flex;">
                            <div class="super-label">реферальні посилання</div>
                            <span class="super-label-question" data-html="true" data-placement="bottom" data-select="true" data-toggle="tooltip" title="реферальні посилання Необхідно вказувати місце реєстрації власника згідно даних тех. паспорта.">
                            <svg width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <circle cx="8" cy="8" r="7.5" fill="white" stroke="#9FA3AB"/>
                                <path d="M7.772 4.848C7.18 4.848 6.688 5.104 6.296 5.616L5.48 4.692C5.704 4.324 6.028 4.028 6.452 3.804C6.876 3.58 7.344 3.468 7.856 3.468C8.64 3.468 9.252 3.672 9.692 4.08C10.148 4.48 10.376 4.98 10.376 5.58C10.376 6.108 10.272 6.536 10.064 6.864C9.856 7.184 9.564 7.496 9.188 7.8C9.14 7.84 9.076 7.892 8.996 7.956C8.924 8.02 8.864 8.072 8.816 8.112C8.776 8.144 8.72 8.192 8.648 8.256C8.584 8.32 8.536 8.372 8.504 8.412C8.472 8.444 8.428 8.496 8.372 8.568C8.324 8.632 8.292 8.688 8.276 8.736C8.26 8.784 8.236 8.848 8.204 8.928C8.18 9.008 8.172 9.08 8.18 9.144C8.196 9.208 8.204 9.288 8.204 9.384L6.944 9.396C6.896 9.1 6.892 8.84 6.932 8.616C6.98 8.384 7.08 8.176 7.232 7.992C7.392 7.8 7.52 7.664 7.616 7.584C7.72 7.496 7.884 7.368 8.108 7.2C8.636 6.832 8.9 6.392 8.9 5.88C8.9 5.592 8.792 5.348 8.576 5.148C8.36 4.948 8.092 4.848 7.772 4.848ZM6.68 11.1C6.68 10.868 6.768 10.664 6.944 10.488C7.128 10.304 7.356 10.212 7.628 10.212C7.876 10.212 8.084 10.304 8.252 10.488C8.428 10.664 8.516 10.868 8.516 11.1C8.516 11.364 8.432 11.592 8.264 11.784C8.096 11.968 7.884 12.06 7.628 12.06C7.356 12.06 7.128 11.968 6.944 11.784C6.768 11.592 6.68 11.364 6.68 11.1Z" fill="#9FA3AB"/>
                            </svg>
                        </span>
                        </div>

                        <button @click="showSocialNetworksModal('registration')" class="btn btn-outline-primary btn-invite">Запросити реферала</button>
                    </div>
                    <div class="">
                        <div class="link-title">
                            Реферальне посилання для реєстрації :
                        </div>
                        <div class="link-row">
                            <!--                    <input style="width: 500px; background-color: #F6F7FB;" type="text" class="" v-model="referralLink" placeholder="" />-->
                            <div class="link-text" style="" >{{ referralLink }}</div>
                            <button @click="showQrModalReg" class="btn-icon">
                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                                    <rect width="20" height="20" fill="url(#pattern0_2_1657)"/>
                                    <defs>
                                        <pattern id="pattern0_2_1657" patternContentUnits="objectBoundingBox" width="1" height="1">
                                            <use xlink:href="#image0_2_1657" transform="scale(0.01)"/>
                                        </pattern>
                                        <image id="image0_2_1657" width="100" height="100" xlink:href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGQAAABkCAYAAABw4pVUAAAACXBIWXMAAAsTAAALEwEAmpwYAAAD3UlEQVR4nO2dzW4TMRDHB7gDD8AL8CUhBBJcgEsFL9AHoZc2M6E5IyQeoAV6oOWQB0DqOKgPQIHycQYkLoUbAlWiggbZaUuF4iU4ttfN/n+ST5v1jv3P2Lv27CwRAAAAAAAAAAAAAAAA1Eard4bYtEjMEonpBpQVYnOPWG9Sp3M0m93T3WMk5pa7trUhzPYl1/a2nqbamTUniM0TEt0hMf1I5S2xXkpu+x1zmVjfxbPb9cEKdZ4ep9rEsJ0XT4j+gcZtUUuvJ7NdzA13jRS2s3lTjyjOM1KIYfYa9tmJHpvO2kkS8yWp7WIeU/Y5I+4w5Svt6LaLzqe3W3fyziminEGMPom+iG+7eZXHdjMX3faKRi1laRTrtwS2f8/0Z3oY3faKRnW9huSqT3S6ojOmK67l68RuoO1x6ws0AoLsAUF2gYcEeIiYGe8Tbkh9OQXxP5nPBNVXiCBxf5dXkLi/SwoE+QME2YX1indYscd8wEMSDVmhQBAI0ow5JJRGe0hd9VURuwMhyJhAkJE6CR4yFoP9Z7/r/29h/TS0LjY/4t/26vbwazkbQvbUh/cD6zJlYxAU0E9e2LxP8KT+IY/tepeyYaND8giyEF0Q1gdZbG/1pigbNlQn/c7bT2o/OxtdkLY57+pOaru+zhrO5LChOqkiN8Q1ar7y+qGCDGzvJLR7i7h3kWrBhurY6JDYniFWjP6RZILYugeiRPYU3SRZvUa1MojPahObdbcHHtIQezdlJ3A2C5XDVDRBdmmtniMxi26itzYE2e7a/JxYpb4gOQAAAAAAkBt3OzqkzJmr3nPsMd95Pjprp7zn2GN121cMIRtAJYYB1bU9EB0IUhgQpDAgSGFAkMKAIIVRwm0v633/HrjnnLa57T3HHfOcN2qUfKORwm+jG4dAkLIQCFIWAkHKQiBIWUiTBXG5Q3SeWF+Gv5Sv28T60QWx2bipUcDioi+rTuRELmx+uRCdVGFAE7vaa/NNpQyUYytKBRDkr0xsUZN/+Txl9QIEGQWbFi+lGLJfFiHI5L+O0J+8OaT0F3baAYuBbkEy4mJl1WJq4176lKY9h5TQgVVAEAhCh8JD6kocIBiyIIjAQ/zAQ2rwEMZtb7qOLv0fLeU/GEIQOZyCHN4kmBPpISnrEwgCQQQe0oeHjAKbRxVjcbzC5mvxQ2AR5PpcBZv1MPuaJoj9WEmOD7qwSpB9jRPk35tUEYpuBn/yqJGC2EQr9gNYacTYGiurTiMF2RdFl6MOX2w2xs431VhBDs4prLPBH5a0otocha3eVJRMbKOuEMRIqgkAAAAAAAAAAAAAAABUAL8BGluQu2recL0AAAAASUVORK5CYII="/>
                                    </defs>
                                </svg>
                            </button>
                            <button @click="copyToBuffer()" class="btn-icon">
                                <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M1.75 0C1.28587 0 0.840752 0.184375 0.512563 0.512563C0.184375 0.840752 0 1.28587 0 1.75V11.25C0 12.216 0.784 13 1.75 13H3.25C3.44891 13 3.63968 12.921 3.78033 12.7803C3.92098 12.6397 4 12.4489 4 12.25C4 12.0511 3.92098 11.8603 3.78033 11.7197C3.63968 11.579 3.44891 11.5 3.25 11.5H1.75C1.6837 11.5 1.62011 11.4737 1.57322 11.4268C1.52634 11.3799 1.5 11.3163 1.5 11.25V1.75C1.5 1.6837 1.52634 1.62011 1.57322 1.57322C1.62011 1.52634 1.6837 1.5 1.75 1.5H11.25C11.3163 1.5 11.3799 1.52634 11.4268 1.57322C11.4737 1.62011 11.5 1.6837 11.5 1.75V3.25C11.5 3.44891 11.579 3.63968 11.7197 3.78033C11.8603 3.92098 12.0511 4 12.25 4C12.4489 4 12.6397 3.92098 12.7803 3.78033C12.921 3.63968 13 3.44891 13 3.25V1.75C13 1.28587 12.8156 0.840752 12.4874 0.512563C12.1592 0.184375 11.7141 0 11.25 0H1.75ZM6.75 5C6.28587 5 5.84075 5.18437 5.51256 5.51256C5.18437 5.84075 5 6.28587 5 6.75V16.25C5 17.216 5.784 18 6.75 18H16.25C16.7141 18 17.1592 17.8156 17.4874 17.4874C17.8156 17.1592 18 16.7141 18 16.25V6.75C18 6.28587 17.8156 5.84075 17.4874 5.51256C17.1592 5.18437 16.7141 5 16.25 5H6.75ZM6.5 6.75C6.5 6.6837 6.52634 6.62011 6.57322 6.57322C6.62011 6.52634 6.6837 6.5 6.75 6.5H16.25C16.3163 6.5 16.3799 6.52634 16.4268 6.57322C16.4737 6.62011 16.5 6.6837 16.5 6.75V16.25C16.5 16.3163 16.4737 16.3799 16.4268 16.4268C16.3799 16.4737 16.3163 16.5 16.25 16.5H6.75C6.6837 16.5 6.62011 16.4737 6.57322 16.4268C6.52634 16.3799 6.5 16.3163 6.5 16.25V6.75Z" fill="#007BBB"/>
                                </svg>
                            </button>
                        </div>
                    </div>
                    <hr>
                    <div class="">
                        <div class="link-title ukasko-link-title">
                            <div>Реферальне посилання для оформлень :</div>
                            <select
                                @change="showUkaskoLink"
                                v-model="ukaskoLinkType"
                                class="form-control referralLinkSelect"
                                id="referralLinkSelect"
                            >
                                <option :value="orderType.id" v-for="orderType in getOrderTypes()" >{{ orderType.name }}</option>
                            </select>
                        </div>
                        <div class="link-row">
                            <div class="link-text" style="" >{{ referralLinkUkasko }}</div>
                            <button @click="showQrModalUkasko" class="btn-icon">
                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                                    <rect width="20" height="20" fill="url(#pattern0_2_1657)"/>
                                    <defs>
                                        <pattern id="pattern0_2_1657" patternContentUnits="objectBoundingBox" width="1" height="1">
                                            <use xlink:href="#image0_2_1657" transform="scale(0.01)"/>
                                        </pattern>
                                        <image id="image0_2_1657" width="100" height="100" xlink:href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGQAAABkCAYAAABw4pVUAAAACXBIWXMAAAsTAAALEwEAmpwYAAAD3UlEQVR4nO2dzW4TMRDHB7gDD8AL8CUhBBJcgEsFL9AHoZc2M6E5IyQeoAV6oOWQB0DqOKgPQIHycQYkLoUbAlWiggbZaUuF4iU4ttfN/n+ST5v1jv3P2Lv27CwRAAAAAAAAAAAAAAAA1Eard4bYtEjMEonpBpQVYnOPWG9Sp3M0m93T3WMk5pa7trUhzPYl1/a2nqbamTUniM0TEt0hMf1I5S2xXkpu+x1zmVjfxbPb9cEKdZ4ep9rEsJ0XT4j+gcZtUUuvJ7NdzA13jRS2s3lTjyjOM1KIYfYa9tmJHpvO2kkS8yWp7WIeU/Y5I+4w5Svt6LaLzqe3W3fyziminEGMPom+iG+7eZXHdjMX3faKRi1laRTrtwS2f8/0Z3oY3faKRnW9huSqT3S6ojOmK67l68RuoO1x6ws0AoLsAUF2gYcEeIiYGe8Tbkh9OQXxP5nPBNVXiCBxf5dXkLi/SwoE+QME2YX1indYscd8wEMSDVmhQBAI0ow5JJRGe0hd9VURuwMhyJhAkJE6CR4yFoP9Z7/r/29h/TS0LjY/4t/26vbwazkbQvbUh/cD6zJlYxAU0E9e2LxP8KT+IY/tepeyYaND8giyEF0Q1gdZbG/1pigbNlQn/c7bT2o/OxtdkLY57+pOaru+zhrO5LChOqkiN8Q1ar7y+qGCDGzvJLR7i7h3kWrBhurY6JDYniFWjP6RZILYugeiRPYU3SRZvUa1MojPahObdbcHHtIQezdlJ3A2C5XDVDRBdmmtniMxi26itzYE2e7a/JxYpb4gOQAAAAAAkBt3OzqkzJmr3nPsMd95Pjprp7zn2GN121cMIRtAJYYB1bU9EB0IUhgQpDAgSGFAkMKAIIVRwm0v633/HrjnnLa57T3HHfOcN2qUfKORwm+jG4dAkLIQCFIWAkHKQiBIWUiTBXG5Q3SeWF+Gv5Sv28T60QWx2bipUcDioi+rTuRELmx+uRCdVGFAE7vaa/NNpQyUYytKBRDkr0xsUZN/+Txl9QIEGQWbFi+lGLJfFiHI5L+O0J+8OaT0F3baAYuBbkEy4mJl1WJq4176lKY9h5TQgVVAEAhCh8JD6kocIBiyIIjAQ/zAQ2rwEMZtb7qOLv0fLeU/GEIQOZyCHN4kmBPpISnrEwgCQQQe0oeHjAKbRxVjcbzC5mvxQ2AR5PpcBZv1MPuaJoj9WEmOD7qwSpB9jRPk35tUEYpuBn/yqJGC2EQr9gNYacTYGiurTiMF2RdFl6MOX2w2xs431VhBDs4prLPBH5a0otocha3eVJRMbKOuEMRIqgkAAAAAAAAAAAAAAABUAL8BGluQu2recL0AAAAASUVORK5CYII="/>
                                    </defs>
                                </svg>
                            </button>
                            <button @click="copyToBuffer(referralLinkUkasko)" class="btn-icon">
                                <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M1.75 0C1.28587 0 0.840752 0.184375 0.512563 0.512563C0.184375 0.840752 0 1.28587 0 1.75V11.25C0 12.216 0.784 13 1.75 13H3.25C3.44891 13 3.63968 12.921 3.78033 12.7803C3.92098 12.6397 4 12.4489 4 12.25C4 12.0511 3.92098 11.8603 3.78033 11.7197C3.63968 11.579 3.44891 11.5 3.25 11.5H1.75C1.6837 11.5 1.62011 11.4737 1.57322 11.4268C1.52634 11.3799 1.5 11.3163 1.5 11.25V1.75C1.5 1.6837 1.52634 1.62011 1.57322 1.57322C1.62011 1.52634 1.6837 1.5 1.75 1.5H11.25C11.3163 1.5 11.3799 1.52634 11.4268 1.57322C11.4737 1.62011 11.5 1.6837 11.5 1.75V3.25C11.5 3.44891 11.579 3.63968 11.7197 3.78033C11.8603 3.92098 12.0511 4 12.25 4C12.4489 4 12.6397 3.92098 12.7803 3.78033C12.921 3.63968 13 3.44891 13 3.25V1.75C13 1.28587 12.8156 0.840752 12.4874 0.512563C12.1592 0.184375 11.7141 0 11.25 0H1.75ZM6.75 5C6.28587 5 5.84075 5.18437 5.51256 5.51256C5.18437 5.84075 5 6.28587 5 6.75V16.25C5 17.216 5.784 18 6.75 18H16.25C16.7141 18 17.1592 17.8156 17.4874 17.4874C17.8156 17.1592 18 16.7141 18 16.25V6.75C18 6.28587 17.8156 5.84075 17.4874 5.51256C17.1592 5.18437 16.7141 5 16.25 5H6.75ZM6.5 6.75C6.5 6.6837 6.52634 6.62011 6.57322 6.57322C6.62011 6.52634 6.6837 6.5 6.75 6.5H16.25C16.3163 6.5 16.3799 6.52634 16.4268 6.57322C16.4737 6.62011 16.5 6.6837 16.5 6.75V16.25C16.5 16.3163 16.4737 16.3799 16.4268 16.4268C16.3799 16.4737 16.3163 16.5 16.25 16.5H6.75C6.6837 16.5 6.62011 16.4737 6.57322 16.4268C6.52634 16.3799 6.5 16.3163 6.5 16.25V6.75Z" fill="#007BBB"/>
                                </svg>
                            </button>
                        </div>
                    </div>

                </div>

                <div class="swiper-slide info-stat col-lg-6" shadow="never">
                    <div class="">
                        <div class="super-label-box" style="display: flex;">
                            <div class="super-label">Реферальний заробіток</div>
                            <div class="super-label-question" data-html="true" data-placement="bottom" data-select="true" data-toggle="tooltip" title="реферальні посилання Необхідно вказувати місце реєстрації власника згідно даних тех. паспорта.">
                                <svg width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <circle cx="8" cy="8" r="7.5" fill="white" stroke="#9FA3AB"/>
                                    <path d="M7.772 4.848C7.18 4.848 6.688 5.104 6.296 5.616L5.48 4.692C5.704 4.324 6.028 4.028 6.452 3.804C6.876 3.58 7.344 3.468 7.856 3.468C8.64 3.468 9.252 3.672 9.692 4.08C10.148 4.48 10.376 4.98 10.376 5.58C10.376 6.108 10.272 6.536 10.064 6.864C9.856 7.184 9.564 7.496 9.188 7.8C9.14 7.84 9.076 7.892 8.996 7.956C8.924 8.02 8.864 8.072 8.816 8.112C8.776 8.144 8.72 8.192 8.648 8.256C8.584 8.32 8.536 8.372 8.504 8.412C8.472 8.444 8.428 8.496 8.372 8.568C8.324 8.632 8.292 8.688 8.276 8.736C8.26 8.784 8.236 8.848 8.204 8.928C8.18 9.008 8.172 9.08 8.18 9.144C8.196 9.208 8.204 9.288 8.204 9.384L6.944 9.396C6.896 9.1 6.892 8.84 6.932 8.616C6.98 8.384 7.08 8.176 7.232 7.992C7.392 7.8 7.52 7.664 7.616 7.584C7.72 7.496 7.884 7.368 8.108 7.2C8.636 6.832 8.9 6.392 8.9 5.88C8.9 5.592 8.792 5.348 8.576 5.148C8.36 4.948 8.092 4.848 7.772 4.848ZM6.68 11.1C6.68 10.868 6.768 10.664 6.944 10.488C7.128 10.304 7.356 10.212 7.628 10.212C7.876 10.212 8.084 10.304 8.252 10.488C8.428 10.664 8.516 10.868 8.516 11.1C8.516 11.364 8.432 11.592 8.264 11.784C8.096 11.968 7.884 12.06 7.628 12.06C7.356 12.06 7.128 11.968 6.944 11.784C6.768 11.592 6.68 11.364 6.68 11.1Z" fill="#9FA3AB"/>
                                </svg>
                            </div>
                        </div>
                    </div>
                    <div class="statistic-wrap">
                        <div class="statistic-tabs-block">
                            <div class="statistic-tabs tabs-block">
                                <ul class="status-tabs">
                                    <li @click="activateStatisticTab(tab)" v-for="(tab, index) in statisticTabs" class="tab">
                                        <a v-bind:class="[ 'tab-item', 'tab',  {active: statisticTabsQuery.tabId === tab.id }]"
                                           href="javascript:void(0);"> {{ tab.name }}</a>
                                    </li>
                                    <!--                        <li @click="activateTab(1)" v-bind:class="[ 'tab-item', 'tab',  {active: listQuery.tabId === status.id }]">-->
                                    <!--                            <a>Реферальні замовлення</a>-->
                                    <!--                        </li>-->
                                    <!--                        <li @click="activateTab(2)" v-bind:class="[ 'tab-item', 'tab',  {active: listQuery.tabId === status.id }]">-->
                                    <!--                            <a>Реферальні реєстрації</a>-->
                                    <!--                        </li>-->
                                </ul>
                            </div>
                        </div>
                        <div v-if="isLinkStatisticTab()" class="">
                            <div class="statistic-times">
                                <div class="ti-head">
                                    <div class="ti-head-title">Відображати реферальний заробіток:</div>
                                    <!--                                <div class="ti-head-choice">-->
                                    <!--                                    <select-->
                                    <!--                                        @change="showReferralUserBonus()"-->
                                    <!--                                        v-model="referralId"-->
                                    <!--                                        class="form-control referralUsersBonusesSelect"-->
                                    <!--                                        id="referralUsersSelect"-->
                                    <!--                                    >-->
                                    <!--                                        <option value="">Всі реферали</option>-->
                                    <!--                                        <option :value="referralUser.user_id" v-for="referralUser in referralUsers" >{{ referralUser.fio }}</option>-->
                                    <!--                                    </select>-->
                                    <!--                                </div>-->
                                </div>
                                <div class="ti-items">
                                    <div class="ti-item">
                                        <div class="ti-name">Весь час</div>
                                        <div class="ti-text">
                                            <span class="__number">{{ getBonusUkaskoSum('allTime') }}</span>
                                            <span class="__word">грн</span>
                                        </div>
                                    </div>
                                    <div class="ti-item">
                                        <div class="ti-name">Місяць</div>
                                        <div class="ti-text">
                                            <span class="__number">{{ getBonusUkaskoSum('month') }}</span>
                                            <span class="__word">грн</span>
                                        </div>
                                    </div>
                                    <div class="ti-item">
                                        <div class="ti-name">День</div>
                                        <div class="ti-text">
                                            <span class="__number">{{ getBonusUkaskoSum('today') }}</span>
                                            <span class="__word">грн</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <hr>
                            <div class="statistic-products">
                                <div class="pr-item" v-for="refCommission in referralOrderCommissions" :key="refCommission.id">
                                    <div class="pr-name">{{ refCommission.productName }}:</div>
                                    <div class="pr-text">
                                        <span class="__word">без промо: </span>
                                        <span class="__number" style="margin-left: 5px;"> {{ refCommission.commission }}% </span>
                                        <span v-if="[1,5].includes(refCommission.productId)" class="__word">з промо: </span>
                                        <span v-if="[1,5].includes(refCommission.productId)" class="__number" style="margin-left: 5px;"> {{ refCommission.commissionPromo }}% </span>
                                    </div>
                                </div>
                                <!--                        <div class="pr-item">-->
                                <!--                            <div class="pr-name">Туризм:</div>-->
                                <!--                            <div class="pr-text">-->
                                <!--                                <span class="__number" style="">0,5%</span>-->
                                <!--                                <span class="__word">з кожного полісу</span>-->
                                <!--                            </div>-->
                                <!--                        </div>-->
                                <!--                        <div class="pr-item">-->
                                <!--                            <div class="pr-name">ДЦВ:</div>-->
                                <!--                            <div class="pr-text">-->
                                <!--                                <span class="__number" style="">0,5%</span>-->
                                <!--                                <span class="__word">з кожного полісу</span>-->
                                <!--                            </div>-->
                                <!--                        </div>-->
                                <!--                        <div class="pr-item">-->
                                <!--                            <div class="pr-name">Зелена карта:</div>-->
                                <!--                            <div class="pr-text">-->
                                <!--                                <span class="__number" style="">0,5%</span>-->
                                <!--                                <span class="__word">з кожного полісу</span>-->
                                <!--                            </div>-->
                                <!--                        </div>-->
                            </div>
                        </div>
                        <div v-else-if="isRegStatisticTab()" class="">
                            <div class="statistic-times">
                                <div class="ti-head">
                                    <div class="ti-head-title">Відображати реферальний заробіток:</div>
                                    <div class="ti-head-choice">
                                        <select
                                            @change="showReferralUserBonus()"
                                            v-model="referralId"
                                            class="form-control referralUsersBonusesSelect"
                                            id="referralUsersSelect"
                                        >
                                            <option value="">Всі реферали</option>
                                            <option :value="referralUser.user_id" v-for="referralUser in referralUsers" >{{ referralUser.fio }}</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="ti-items">
                                    <div class="ti-item">
                                        <div class="ti-name">Весь час</div>
                                        <div class="ti-text">
                                            <span class="__number">{{ getBonusRegistrationSum('allTime') }}</span>
                                            <span class="__word">бонусів</span>
                                        </div>
                                    </div>
                                    <div class="ti-item">
                                        <div class="ti-name">Місяць</div>
                                        <div class="ti-text">
                                            <span class="__number">{{ getBonusRegistrationSum('month') }}</span>
                                            <span class="__word">бонусів</span>
                                        </div>
                                    </div>
                                    <div class="ti-item">
                                        <div class="ti-name">День</div>
                                        <div class="ti-text">
                                            <span class="__number">{{ getBonusRegistrationSum('today') }}</span>
                                            <span class="__word">бонусів</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <hr>
                            <div class="statistic-products">
                                <div class="pr-item" v-for="refPercent in referralRegistrationPercents" :key="refPercent.id">
                                    <div class="pr-name">{{ refPercent.productName }}:</div>
                                    <div class="pr-text">
                                        <span class="__number" style="">{{ refPercent.percent }}%</span>
                                        <span class="__word">з кожного полісу</span>
                                    </div>
                                </div>
                                <!--                        <div class="pr-item">-->
                                <!--                            <div class="pr-name">Туризм:</div>-->
                                <!--                            <div class="pr-text">-->
                                <!--                                <span class="__number" style="">0,5%</span>-->
                                <!--                                <span class="__word">з кожного полісу</span>-->
                                <!--                            </div>-->
                                <!--                        </div>-->
                                <!--                        <div class="pr-item">-->
                                <!--                            <div class="pr-name">ДЦВ:</div>-->
                                <!--                            <div class="pr-text">-->
                                <!--                                <span class="__number" style="">0,5%</span>-->
                                <!--                                <span class="__word">з кожного полісу</span>-->
                                <!--                            </div>-->
                                <!--                        </div>-->
                                <!--                        <div class="pr-item">-->
                                <!--                            <div class="pr-name">Зелена карта:</div>-->
                                <!--                            <div class="pr-text">-->
                                <!--                                <span class="__number" style="">0,5%</span>-->
                                <!--                                <span class="__word">з кожного полісу</span>-->
                                <!--                            </div>-->
                                <!--                        </div>-->
                            </div>
                        </div>


                    </div>
                </div>
            </div>
            <!-- If we need pagination -->
            <div class="swiper-pagination"></div>
            <!-- If we need navigation buttons -->
<!--            <div class="swiper-button-prev" v-if="!condition.isMobile"></div>-->
<!--            <div class="swiper-button-next" v-if="!condition.isMobile"></div>-->
        </div>



<!--        <div ref="swiper" class="swiper row referrals-wrap " >-->
<!--            <div class="swiper-wrapper">-->

<!--            </div>-->
<!--            <div class="swiper-pagination"></div>-->
<!--                <div class="" :class="condition.isMobile ? 'swiper' : 'p-4'">-->
<!--                    <div class="row referrals-wrap " :class="condition.isMobile ? 'swiper-wrapper' : ''">-->
<!--                        <div :class="[{'swiper-slide': condition.isMobile} ,'info-stat', 'col-lg-6', ]">-->
<!--                            -->
<!--                        </div>-->
<!--                    </div>-->
<!--                </div>-->
<!--        </div>-->


        <div class="list-referrals row">
            <div class="referral-list-tabs tabs-block">
                <ul class="status-tabs">
                    <li @click="activateTab(tab)" v-for="(tab, index) in listTabs" class="tab">
                        <a v-bind:class="[ 'tab-item', 'tab',  {active: listTabsQuery.tabId === tab.id }]"
                           href="javascript:void(0);"> {{ tab.name }}</a>
                    </li>
                    <!--                    <li @click="activateTab(status)" v-bind:class="[ 'tab-item', 'tab',  {active: listQuery.tabId === status.id }]">-->
                    <!--                        <a>Реферальні замовлення</a>-->
                    <!--                    </li>-->
                    <!--                    <li @click="activateTab(status)" v-bind:class="[ 'tab-item', 'tab',  {active: listQuery.tabId === status.id }]">-->
                    <!--                        <a>Реферальні реєстрації</a>-->
                    <!--                    </li>-->
                </ul>
            </div>
        </div>

        <div v-if="isReferralsListTab()">
            <referral-list
                ref="referralsList"
                @init-clients-list="initList('referrals')"
                :referrals="referralUsers"
                :is-load-data="condition.isLoadTable"
                :is-mobile="condition.isMobile"
            ></referral-list>
            <div style="text-align: center">
                <pagination v-show="referralsTotal > 0" :total="referralsTotal"
                            :page.sync="listQuery.page"
                            :limit.sync="listQuery.limit" @pagination="initList('referrals')" />
            </div>
        </div>

        <div v-else-if="isOrdersListTab()">
            <referral-orders-list
                ref="referralOrders"
                @init-list="initList()"
                :referral-orders="referralOrders"
                :is-load-data="condition.isLoadTable"
                :is-mobile="condition.isMobile"
            ></referral-orders-list>
            <div style="text-align: center">
                <pagination v-show="ordersTotal > 0" :total="ordersTotal"
                            :page.sync="listQuery.page"
                            :limit.sync="listQuery.limit"
                            :page-sizes="pageSizes"
                            @pagination="initList()" />
            </div>
        </div>


        <modal-qrcode-referral ref="modalQrCodeRegistration" :link="referralLink" :qrcode="qrCode" :show-qrcode="showQrCodeRegistration" :sale-text="saleTextRegistration" type="registration" @showSocialNetworksModal="showSocialNetworksModal"></modal-qrcode-referral>
        <modal-qrcode-referral ref="modalQrCodeLink" :link="referralLinkUkasko" :qrcode="qrCodeUkasko" :show-qrcode="showQrCodeLink" :sale-text="saleTextLink" :sale-label="saleLabel" type="ukasko" @showSocialNetworksModal="showSocialNetworksModal"></modal-qrcode-referral>
        <modal-social-networks-share ref="modalSocialNetworksShare" :data-link="referralLink" :data-title="socialTitle" :data-description="socialDescription" :data-description-viber="socialDescriptionViber"></modal-social-networks-share>
    </div>

</template>


<script>
    import Vue from "vue";
    import {orderMixin, userMixin} from '@/mixin'
    import Swiper from 'swiper/swiper-bundle.min';
    import 'swiper/swiper-bundle.min.css';
    import Pagination from '@/components/Pagination';
    //import { downloadExcel, getData} from "@/api/client";
    import { downloadExcel, getData, getReferralUsers, getReferralUserBonus} from "@/api/referral";
    import { default as clientsList } from "@/views/clients/clientsList";
    import { default as ClientFilterBlock } from "@/views/clients/filterBlock";
    import ModalQrcodeReferral from "../../components/app/modals/modal-qrcode-referral";
    import ModalSocialNetworksShare from "../../components/app/modals/modal-social-networks-share";
    import ReferralList from "@/views/referral/referralList";
    import ReferralOrdersList from "@/views/referral/referralOrdersList";

    const FileDownload = require('js-file-download');

    export default {
        mixins: [userMixin],
        components: {
            ReferralList,
            ReferralOrdersList,
            ModalQrcodeReferral,
            ModalSocialNetworksShare,
            clientsList,
            Pagination,
            ClientFilterBlock,
            downloadExcel,
        },
        data: function () {
            return {
                clients: [],
                total: 1,
                referralsTotal: 1,
                ordersTotal: 1,
                mobileWith: 992,
                condition: {
                    isLoadTable: true,
                    isLoadXml: false,
                    isMobile: false,
                },
                listQuery: {
                    page: 1,
                    limit: 5,
                    tabId: 1,
                },
                pageSizes: [5, 10, 15, 20, 30, 50],
                statisticTabs: [
                    { name: 'Замовлення', id: 1, type: "draft" },
                    { name: 'Реєстрації', id: 2, type: "overdue" },
                ],
                statisticTabsQuery: {
                    tabId: 1,
                },
                listTabs: [
                    { name: 'Реферальні замовлення', id: 1, type: "draft" },
                    { name: 'Реферальні реєстрації', id: 2, type: "overdue" },
                ],
                listTabsQuery: {
                    tabId: 1,
                },
                referralId: '',
                referralUsers: [],
                referralOrders: [],
                referralUsersList: [],
                referralLink: null,
                referralLinkUkasko: null,
                ukaskoLinkType: 1,
                ukaskoLinks: [
                    { id: 1, link: 'insurance/osago' },
                    { id: 3, link: 'insurance/green-card' },
                    { id: 5, link: 'insurance/tourism' },
                ],
                qrCode: null,
                qrCodeUkasko: null,
                statistics: {
                    bonusesRegistration: [],
                    bonusesUkaskoOrders: [],
                },
                referralRegistrationPercents: [],
                referralOrderCommissions: [],
                referralLinkPercents: [
                    {id: 1, productId: 1, productName: "ОСЦПВ", percent: "15", withPromo: "5"},
                    {id: 4, productId: 5, productName: "Туризм", percent: "15",  withPromo: "5"},
                    {id: 2, productId: 4, productName: "ДЦВ", percent: "15"},
                    {id: 3, productId: 3, productName: "Зелена Карта", percent: "15"},
                ],
                showQrCodeLink: false,
                showQrCodeRegistration: false,
                saleTextLink: 'Скануйте QR-код для оформлення страхового полісу та отримуйте ',
                saleLabel: 'знижку -15%',
                saleTextRegistration: 'Скануйте QR-код, реєструйтесь на сайті та отримайте бонуси за реєстрацію',
                //socialTitle: 'Вас запрошено до реєстрації на платформі онлайн-страхування ukasko.connect!',
                // socialDescription: 'Переходьте за посиланням, реєструйтесь на платформі та отримуйте страртові бонуси до 5000 грн.\n' +
                //     'Приєднуйтесь до компанії однодумців та розвивайте ринок страхування разом з ukasko.connect!', //Скануйте QR-код, або п
               // socialTitleUkasko: 'Вас запрошено до платформи онлайн-страхування ukasko!',
               // socialDescriptionUkasko: 'Переходьте за посиланням, на ukasko.ua!', //Скануйте QR-код, або
            }
        },
        created: function() {
            window.addEventListener("resize", this.checkWindowResize);
            this.checkIsMobile();
            this.initData();
            // this.tooltipActivate();
        },
        mounted: function() { // The hook. Here, Vue has already worked its magic.
            this.initList();
            this.initSwiper();
        },
        watch: {
            loading: function (value) {
                if (!value) {
                    this.$refs.swiper.swiper.destroy()
                    this.$nextTick(() => {
                        this.initSwiper()
                    })
                }
            }
        },
        computed: {
            loading() {
                return this.$store.state.levelsStore.loading
            },
            userId: function () {
                return this.$store.getters.USER?.id;
            },
            allOrderTypes: function () {
                return this.$store.getters.ALL_ORDER_TYPES
            },
            socialTitle: function() {
                return `Колега-страховий агент ${this.getUserFullName()} запрошує Вас відкрити нові можливості страхування з UKASKO.Connect!`;
            },
            socialTitleViber: function () {
                return `Колега ${this.getUserFullName()} рекомендує зареєструватись на UKASKO.Connect! Реєструйтесь за ${this.referralLink} та отримайте свій бонус!`;
            },
            socialDescription: function () {
                //`Колега-страховий агент ${this.getUserFullName()} запрошує Вас відкрити нові можливості страхування з UKASKO.Connect!\n` +
                // return `Зареєструйтесь на нашій платформі за цим посиланням: ${this.referralLink} та отримайте стартовий бонус до 5000 грн.` +
                // `Розвивайте свій бізнес разом з командою професіоналів UKASKO.Connect!`;
                return `Зареєструйтесь на нашій платформі за посиланням вище та отримайте стартовий бонус до 5000 грн. Розвивайте свій бізнес разом з командою професіоналів UKASKO.Connect!`;
                // return  `Переходьте за посиланням ${this.referralLink}, реєструйтесь на платформі та отримуйте страртові бонуси до 5000 грн.\n` +
                // `Приєднуйтесь до компанії однодумців та розвивайте ринок страхування разом з ukasko.connect!`; //Скануйте QR-код, або п
            },
            socialDescriptionViber: function () {
                return `Колега ${this.getUserFullName()} рекомендує зареєструватись на UKASKO.Connect! Реєструйтесь за ${this.referralLink} та отримайте свій бонус!`;
            },
            socialTitleUkasko: function () {
                return `Вам надіслано пропозиції страхування ${this.getUkaskoLinkName()}.`;
            },
            socialDescriptionUkasko: function () {
                return `Переходьте за посиланням та оформлюйте страхові поліси зі знижками до -20%`;
            },
            socialDescriptionUkaskoViber: function () {
                return `Вам надіслано пропозиції страхування ${this.getUkaskoLinkName()}. Переходьте за посиланням та оформлюйте страхові поліси зі знижками до -20%`;
            },
        },
        methods: {
            initSwiper() {
                new Swiper(this.$refs.swiper, {
                    // slidesPerView: 'auto',
                    // initialSlide: this.$store.getters.USER_INFO.levelId,
                    // loop: true,
                    initialSlide: 0,//this.levels.findIndex(item => item.id === this.currentLevelId),
                    spaceBetween: 10,
                    // autoHeight: true,
                    pagination: {
                        el: '.swiper-pagination',
                        clickable: true,
                        // enabled: true,
                    },
                    navigation: {
                        nextEl: '.swiper-button-next',
                        prevEl: '.swiper-button-prev',
                    },
                });
            },
            getOrderTypes() {
                let orderTypesFiltered = this.allOrderTypes.filter((item) => {
                    return [1,3].includes(item.id);
                });
                return orderTypesFiltered;
            },
            getUkaskoLinkName() {
                let ukaskoLinkProductName = this.allOrderTypes.find((item) => {
                    return item.id == this.ukaskoLinkType;
                });
                return ukaskoLinkProductName.name;
            },
            showUkaskoLink() {
                let currentUkaskoLink = this.ukaskoLinks.find((item) => {
                    return item.id === this.ukaskoLinkType;
                });
                this.referralLinkUkasko = currentUkaskoLink.link;
            },
            activateStatisticTab :function (tab) {
                this.$set(this.statisticTabsQuery, 'tabId', tab.id);
            },
            isLinkStatisticTab: function () {
                return this.statisticTabsQuery.tabId === 1;
            },
            isRegStatisticTab: function () {
                return this.statisticTabsQuery.tabId === 2;
            },
            activateTab :function (tab) {
                //this.$set(this.listQuery, 'page', 1); // сбрасиваем на 1 страницу при переключении вкладки
                this.$set(this.listTabsQuery, 'tabId', tab.id);
            },
            isReferralsListTab: function () {
                return this.listTabsQuery.tabId === 2;
            },
            isOrdersListTab: function () {
                return this.listTabsQuery.tabId === 1;
            },
            copyToBuffer: function(referralLinkUkasko) {
                navigator.clipboard.writeText(referralLinkUkasko ? referralLinkUkasko : this.referralLink);
            },
            showQrModalReg() {
                //this.showQrCode = true;
                this.$refs.modalQrCodeRegistration.open();
            },
            showQrModalUkasko() {
                this.$refs.modalQrCodeLink.open();
            },
            showSocialNetworksModal(type = 'registration') {
                const socialModalData = {
                    type: type === 'registration' ? 'registration' : 'ukasko',
                    link: type === 'registration' ? this.referralLink : this.referralLinkUkasko,//this.connectDomain : this.ukaskoDomain, //this.referralLink : this.referralLinkUkasko,
                    title: type === 'registration' ? this.socialTitle : this.socialTitleUkasko,
                    description: type === 'registration' ? this.socialDescription : this.socialDescriptionUkasko,
                    titleViber: type === 'registration' ? this.socialTitleViber : this.socialTitleUkasko,
                    descriptionViber: type === 'registration' ? this.socialDescriptionViber : this.socialDescriptionUkaskoViber,
                }
                this.$refs.modalSocialNetworksShare.open(socialModalData);
            },
            initData: async function (filterButtonEvent, fastSearch = false) {
                if (filterButtonEvent === 1) {
                    this.listQuery.page = 1;
                }

                this.condition.isLoadTable = true;
                let response;
                //response = await getData({})

                if(fastSearch){
                  response = await getData(this.getFastQuery())
                }else{
                  response = await getData(this.getQuery())
                }

                if(response){
                    const { data } = response;

                    this.qrCode = data.data?.qrCode;
                    this.referralLink = data.data?.referralLink;
                    this.ukaskoLinks = data.data?.ukaskoReferralLinks;
                    this.referralLinkUkasko = data.data?.referralLinkUkasko;
                    this.qrCodeUkasko = data.data?.qrCodeUkasko;
                    this.referralUsers = data.data.referralUsers.items;
                    this.referralsTotal = data.data?.referralUsers.total;
                    this.referralOrders = data.data.referralOrders.items;
                    this.ordersTotal = data.data?.referralOrders.total;
                    this.statistics.bonusesRegistration = data.data.statistics.bonusesRegistration;
                    this.statistics.bonusesUkaskoOrders = data.data.statistics.bonusesUkaskoOrders;
                    this.referralRegistrationPercents = data.data.referralRegistrationPercents;
                    this.referralOrderCommissions = data.data.referralOrderCommissions;
                    this.connectDomain = data.data.connectDomain;
                    this.ukaskoDomain = data.data.ukaskoDomain;
                }
                this.condition.isLoadTable = false;
            },
            initList: async function (requestType, filterButtonEvent, fastSearch = false) {
                let query = this.getQuery();
                let params = {
                    "requestType": "orders",
                };

                if (requestType) {
                    params.requestType = requestType;
                }
                if (filterButtonEvent === 1) {
                    this.listQuery.page = 1;
                    query = this.getQuery();
                }

                // this.orders = [];
                // this.total = 0;
                // let params = {
                //     // "clientId":this.$route.params.clientId,
                //     "requestType": "orders",
                //     // "active_orders": false,
                //     // "listSubQueryStatusId": this.listSubQuery.statusId,
                // };

                this.condition.isLoadTable = true;
                let response;

                params = {...params, ...query};

                if(fastSearch){
                    response = await getData(this.getFastQuery())
                }else{
                    response = await getData(params)
                }

                if(response){
                    const { data } = response;

                    this.referralOrders = data.data.referralOrders.items;
                    this.ordersTotal = data.data?.referralOrders.total;
                }
                this.condition.isLoadTable = false;
            },

            showReferralUserBonus: async function() {
                let referralId = this.referralId ? this.referralId : null;
                const params = {
                    referral: referralId
                }
                let response = await getReferralUserBonus(params);
                if(response){
                    const { data } = response;
                    this.statistics.bonusesRegistration = data.data.statistics.bonusesRegistration;
                }
            },
            getBonusUkaskoSum(period) {
                const ukaskoSum = this.statistics.bonusesUkaskoOrders.find((item) => {
                    return item.type == period;
                });
                return ukaskoSum?.total;
            },
            getBonusRegistrationSum(period) {
                const registrationSum = this.statistics.bonusesRegistration.find((item) => {
                    return item.type == period;
                });
                return registrationSum?.total;
            },
            tooltipActivate(){
                $(document).ready(function(){
                    $('[data-toggle="tooltip"]').tooltip();
                });
            },
            checkIsMobile: function() {
                return this.condition.isMobile = window.innerWidth < this.mobileWith;
            },
            checkWindowResize: function() {
                this.checkIsMobile()
            },
            filterStatusWrap: function() {
                this.$refs.filter.filterStatusWrap(true);
            },
            getQuery: function () {
                return {...this.listQuery, ...this.$refs.referralOrders?.listQuery, };// ...this.$refs.filter.listQuery, //...this.$refs.referralsList.listQuery,
            },
            getFastQuery: function () {
                return {"fastSearch" : this.$refs.filter.fastSearch};
            },
            downloadXls : async function() {
                Vue.set(this.condition, 'idLoadXml', true);

                let response = await downloadExcel({...this.$refs.filter.listQuery, ...this.$refs.clientList.listQuery});

                if (response?.data) {
                    FileDownload(response.data, 'report.xlsx');
                }

                Vue.set(this.condition, 'idLoadXml', false);
            },

        },
        destroyed() {
            window.removeEventListener("resize", this.checkWindowResize);
        },
    }
</script>

<style scoped>
.dd{

}

.swiper{
    overflow-x: hidden;
    border-radius: 5px;

    @media (max-width: 992px){
        overflow-x: visible;
    }


    .swiper-wrapper {
        border-radius: 5px;
        border-color: #ED79041A;
        width: 100%;
        .swiper-slide {
            border-radius: 5px;
        }
    }

    .swiper-pagination {
        position: relative;
        margin-top:12px;

        .swiper-pagination-bullet-active {
            background-color: #FA9B13 !important;
            opacity: unset;
        }

        .swiper-pagination-bullet {
            border-radius: 50%;
            width: 12px;
            height: 12px;
            background-color: #999DA6;
            opacity: unset;
            margin: 12px 4px;
        }
    }

    .swiper-button-prev {
        left: -5px;
        top: 160px;
        color: #000000;

        &::after {
             font-size: 30px;
         }
    }

    .swiper-button-next {
        right: -5px;
        top: 160px;

        color: #000000;

        &::after {
             font-size: 30px;
         }
    }

    .pu-skeleton {
        line-height: 1.5;
    }
}

</style>
