<template>
    <div v-if="dialogVisible" class="modal-mask referral-link__modal">
        <div class="custom-modal-wrapper">
            <div class="custom-modal-container">
<!--                <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click="close">-->
<!--                    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">-->
<!--                        <rect width="40" height="40" fill="white"/>-->
<!--                        <path d="M28 12L12 28" stroke="black" stroke-width="2"/>-->
<!--                        <path d="M12 12L28 28" stroke="black" stroke-width="2"/>-->
<!--                    </svg>-->
<!--                </button>-->
                <div id="qrImage" class="qr-block">
                    <a href="javascript:void(0)" @click="close()" class="hide">
                        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M17 1L1 17" stroke="black" stroke-width="2"/>
                            <path d="M1 1L17 17" stroke="black" stroke-width="2"/>
                        </svg>
                    </a>
                    <a :href="link"  class="qr__wrap" target="_blank">
                        <div class="qr__logo">
                            <img src="/images/ukasko-logo.svg" />
                        </div>
                        <div class="qr__box">
                            <img :src="qrcode" alt="">
                        </div>
                        <div class="qr__text">
                            <div class="sale-text">
                                <span>{{ saleText }}</span> <span v-if="saleLabel" class="sale-label">знижку -15%</span>
                            </div>
                        </div>
                    </a>
                    <div class="col-lg-12 text-center position-relative qr__buttons">
                        <!--                        <button  @click="close()" class="btn-outline-blue btn" data-dismiss="modal">-->
                        <!--                          Скачати-->
                        <!--                        </button>-->
                        <a @click="download()" id="registrationQrCode" class="btn-outline-blue" data-dismiss="modal" download>
                            <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                                <rect width="50" height="50" fill="url(#pattern0_9_1036)"/>
                                <defs>
                                    <pattern id="pattern0_9_1036" patternContentUnits="objectBoundingBox" width="1" height="1">
                                        <use xlink:href="#image0_9_1036" transform="scale(0.01)"/>
                                    </pattern>
                                    <image id="image0_9_1036" width="100" height="100" xlink:href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGQAAABkCAYAAABw4pVUAAAACXBIWXMAAAsTAAALEwEAmpwYAAAD3klEQVR4nO2dyWsUQRSHn7giKHrRgxdBL+pBccHl4HJyiXrzIJ5cbx6lkqoZBvGgIv4TIgoat7hlXk0IiEdvgoooiB4U94gHUWKkarJMTHfPTJLqeaR+HzwY0ky/qfd1d9VUqqeJAAAAAAAAAAAAAAAA+aArS0jbE9WoLEHZW0lHZQdp20fGDviovt4OKa1C86thGSNSXkJIKyiVF42RMRRuG8gZ9XBpqhC3DUBI3CicIbJQECILBSGyUBAiCwUhslAQIgsFIbJQECILBSGyUBAiCwUhslAQIgsFIbJQECILBSGyUBAiCwUhslAQIgsFIbJQECILBSGyUBAiCwUhslAQIgsFIbJQECILBSGyUBAiCwUhslAQEoZS7xwylaOk+QwV7N7chejyPp/b8GH/WaKm9GA+Gft09C1pfJ1OPpgdXIjLYbjzv/c+pVOP51G0+CMzoaCa71Lp2qxgQty+XY7E3PY0RYux91KLqu2dTCnjFeJl2Dup7zW2i6JF88WMwgxkShmPkPoyXM4LFC0dlcVk7MfMAhm+nSilWSFuH25fmbnsR9zBW+heTZo/1Tlq74/p6JsRcuLJTNJ8q46Mr1Qsrwtw2E1ZKTd8YZsVcuDadDJ8NXvf/I00r29R6wVLMfy5YSmNCIGMCaLLaxqWUk+Ik6HtlbpnRtFumPjRFLsUw53U3r08fXvPsnhkFHpW+S90ms+T5k1BchTL63wnmy3l0fi2Be7A2+3mwdqc8bUKirG7yNhfNZePftL2SJBcmtf7ozi7sM1HyA5c8zFfk5F8v0jzTgqGsc8Trul9VOqdESRf0W6YVCkhL1OuXzP8I6E+z4Lko1LXXDL8N7GhBV4RJilNnpTQfUZHZWXK5fOvr92kU+pdkN7Y8hoKia6srd+nZMZ3KpY3hh+MpOR3tZtSQhru6JM+m+0LNviIWsh4pOQlI1ohw8PKmh++TI+fZMpbKS+iFdKYlHxlUOxCHKZnS+Iw08no4G2UN9ELGZKi+V3N0Pad/1srgJBB3KKEAu/x0coFChAiDAgRBoQIA0JiF+IXCoyaWq4Z9lZ2U+xo25YyW9AfbDacDH9ISXqJYkfz5eTa8PtwSY29mTFvdDbKdbElvz75XPqsAXeGS27soTqTef2k+Q0Zfh1FaH6TehkfEXIwnJDqSo5nDc+2xh6aX4TrP0Y9Zoj/tLyxRnrw7/zm1KoP4apzqkYc2tWGj1OuFHj/BP+tOjVD2y9N3Qk2qbTfW+hHGLUzrrGGtm/9SNPVpPUMTKsuoLNtfp3W8HMFp3wc8W12K05cDQAAAAAAAAAAAAAAAAAAQAL5B6d/WkhCM4KFAAAAAElFTkSuQmCC"/>
                                </defs>
                            </svg>
                        </a>
<!--                                                  <a  id="registrationQrCode" ref="sss" class="btn-outline-blue btn" href="" download>-->
<!--                                                      Скачати2-->
<!--                                                  </a>-->
                        <button type="button" class="" data-dismiss="modal" aria-label="Close" @click="showSocials">
                            <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                                <rect width="50" height="50" fill="url(#pattern0_9_1038)"/>
                                <defs>
                                    <pattern id="pattern0_9_1038" patternContentUnits="objectBoundingBox" width="1" height="1">
                                        <use xlink:href="#image0_9_1038" transform="scale(0.01)"/>
                                    </pattern>
                                    <image id="image0_9_1038" width="100" height="100" xlink:href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGQAAABkCAYAAABw4pVUAAAACXBIWXMAAAsTAAALEwEAmpwYAAADq0lEQVR4nO3dO2gUQRgH8M8XKuIrCBIFQQXBQiysFBQ7K+3S2WhAUBANS8zdNxe3UEHQSkHQyhcIFlaa5ObbcIViEUQQrNRKEUVFolEUX5FdSTB6c7m7fX25+/9gi0Buudk/s7M7Nw8iAAAAAAAAjfyhDmLpJyPXiKU3+hvyDMM+IyPjkwfLB2J7AsHkwUhpShgIJmdsrzsDQY3JAdu+aQNBMBniwU4y9lvdoURtjB0jI6fRxqTFyK2GAkEwKWO7q6lAEExaxmcRy5NYoeBWljATHIsdCBr/BPmVFWTka/WLbC2V7BEy9jVqTJaMveG4FX0nDlaTV16EYLJkZKe7Btjjk/+HYDLE9rGjlrygrptzpvwvgskA26POWlKye6p+BsGkyK8sI5bPjqenOzU/i2BSwvayI5CfZMprp/08gklYQbbWeMc4Vfd5EEyC2D50NO6v6MCDeQ2dC8EkwMjBGo/AXU2d08N7TPN67y0mYz86QglinJkQTLOMveioIb+oIBtihRJCjWlQaWhzjb6rs5QUD7ey+rEdcdSSd+RXFiQWSgjB1MEE3c5aUizvpTR4qDFuPfcXkpH3jneSu6kEMgHBOLA95+7fkk2UNg81ZqqS3Rg9WVUP5XzqgUxAMH8Jb0/VAxmNLlSWPNQYihpw5yNw0J1pIBPaOpjDA/PJyBtHAUdy/W5euwbDcsZZuP7ylry/HrVdMGZ4vbNxZ7lEWnjtFEzYsVi9QJ/IH1hCmnjtEEzY9e4siBwijbxWDsavzCWWl9ULYR+RZl6rBmPkpLsAw9tIO6/VgumTNWTkh6OWXKGZwmulYMIhQdW/7Jeof6sg6zI7fLuKfH92rF9HWYrE9m1jwWiaHMvl3Q19+bQPDmcSl3fEKlOcYIzdR7kKh5WyPM89CPPPhSkGK2OXralg7C8qBtspV38WGxjXdQTJ9as1GgzLBcqVxkBY9rdnIBpvWUZGc7tlhcNsc71laWvUjTyNfUFmdKOu6bGXBzujiatt+9iLF8MxXS+G6DrpIDXQuagMut+VwQ9UiuAnXGUwyEERDANSBgPllMFQUkUw2FoZTEdQBBN2lMGUNmUw6VMRTItWBgsHKIKlNZTB4jPKYHkmRbCAmTJY4k8RLIKpDJaJVQYLKSuCpcaVwWL8imC7CmWwoYsm2PJIF2wKpgy2zVMEG0sqE27XPeNmDLUylqsIQhO2BjVCk8Lt5f/t8olbU876ZGk0CzUc1FCSHrQRAAAAAAAA1DJ+A2wzw7DNNOM3AAAAAElFTkSuQmCC"/>
                                </defs>
                            </svg>
                        </button>
                    </div>
                    <div v-if="this.preloader" class="modal-preloader">
                        <div class="spinner"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import VueSocialSharing from 'vue-social-sharing'
import html2canvas from "html2canvas";

export default {
    name: 'ModalQrcodeReferral',
    props: ['type', 'link', 'qrcode', 'saleText', 'saleLabel'],
    components: {
        VueSocialSharing,
    },
    data() {
        return {
            dialogVisible: false,
            preloader: false,
        };
    },
    created() {

    },
    mounted() {

    },
    methods: {
        close() {
            this.dialogVisible = false;
        },
        toggleModal(){
            this.showModal = !this.showModal;
        },
        open: function (orderId, price) {
            this.dialogVisible = true;
        },
        showSocials:function (){
            this.dialogVisible = false;
            this.$emit('showSocialNetworksModal', this.type);
        },
        download() {
            const imageTarget = document.getElementById('qrImage');
            if (imageTarget) {
                html2canvas(imageTarget).then(function(canvas) {
                   // document.body.appendChild(canvas);
                    const downloadImage = canvas.toDataURL('image/png');
                    const registrationQrCode = document.getElementById('registrationQrCode');
                    if (!registrationQrCode.href) {
                        registrationQrCode.href = downloadImage;
                        registrationQrCode.click();
                    }
                });
            }
        },
    }
};
</script>


<style lang="scss" scoped>

.modal-mask {
    position: fixed;
    z-index: 9998;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: table;
    transition: opacity 0.3s ease;
}

.referral-link__modal{

    .qr-block{

    }
    .hide {
        position: absolute;
        right: 12px;
        top: 12px;
    }
    .qr__wrap{
        &:hover{
            text-decoration: none;
        }
        background-color: #FFAD3D;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        border-radius: 5px;
    }
    .qr__logo{
        padding: 18px 0;
    }
    .qr__box{
        padding: 25px;
        background-color: #fff;
        border-radius: 5px;
    }
    .qr__text{
        max-width: 310px;
        padding: 20px 0;
        .sale-text{
            text-align: center;
            color: #fff;
            font-size: 19px;
            line-height: 30px;
        }
        .sale-label{
            text-align: center;
            background-color: #fff;
            border-radius: 30px;
            padding: 0px 12px;
            color: #FFAD3D;
            font-size: 20px;
            font-weight: bold;
            margin: 0 auto;
            margin-top: 10px;
            width: 150px;

        }
    }
    .qr__buttons{
        margin-top: 15px;
        margin-bottom: -15px;
        button, a{
            border: none;
            background: none;
            svg{
               width: 50px;
            }
            &:hover{
                background-color: #fff;
            }
        }
        .btn{
            margin-top: 0;
        }
    }
    @media (max-width: 768px) {
        .qr__logo{
            padding: 14px 0;
            img{
                width: 82px;
            }
        }
        .qr__box{
            padding: 5px;
        }
        .qr__text{
            display: flex;
            justify-content: center;
            padding: 10px 0 14px 0;
            .sale-text{
                text-align: left;
                width: 220px;
                font-size: 14px;
                line-height: 20px;
            }
            .sale-label{
                font-size: 15px;
                padding: 2px 6px;
            }
        }
        .qr__buttons {
            button, a {
                svg {
                    width: 32px;
                    height: 32px;
                }
            }
        }
    }
}



.custom-modal-wrapper {
    display: table-cell;
    vertical-align: middle;
}

.custom-modal-container {
    max-width: 520px;
    @media (max-width: 768px) {
        max-width: 360px;
    }

    .hr-grey {
        width: 400px;
        //height: 0px;
        //border: 0.5px solid #DDE2E5;
    }
    .policy-link {
        width: 436px;
        height: 64px;
        background: #F6F7FB;
        border-radius: 5px;
        display: flex;
        @media (max-width: 992px) {
            width: 300px;
        }

        .link {
            margin: auto 0 auto 12px;
            white-space: nowrap;
            overflow: hidden;
            max-width: 67%;
            padding: 5px;
            text-overflow: ellipsis;

            @media (max-width: 992px) {
                margin: auto 0 auto 2px;
            }
        }
    }
    .message {
        width: 258px; height: 29px;font-family: Circe,serif;
        font-style: normal;
        font-weight: bold;
        font-size: 20px;
        line-height: 29px;
        text-align: center;
        color: #24282C;

        @media (max-width: 992px) {
            width: 258px;
            height: 21px;
            font-size: 17px;
            line-height: 20px;
        }
    }

    @media (max-width: 992px) {
        //width:358px;
    }
    @media (max-width: 768px) {
        padding: 40px 25px 34px 25px;
    }
    position: relative;
    width: 516px;
    height: auto;
    background: #FFFFFF;
    border-radius: 5px;
    margin: 0 auto;
    padding: 40px 30px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
    transition: all 0.3s ease;
    font-family: Helvetica, Arial, sans-serif;
}

.custom-modal-custom-header h3 {
    margin-top: 0;
    color: #42b983;
}

.custom-modal-body {
    margin: 20px 0;
}

.modal-default-button {
    float: right;
}

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter {
    opacity: 0;
}

.modal-leave-active {
    opacity: 0;
}

.modal-enter .custom-modal-container,
.modal-leave-active .custom-modal-container {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
}


</style>
