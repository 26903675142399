<template>
    <div class="p-0 row">
        <div class="list-referrals referral-orders container p-0">
            <div v-if="! isLoadData && (referralOrders && referralOrders.length < 1)" class="container empty-notification" >
                <img src="~@/assets/images/empty_img.svg" alt="">
                <div class="empty-notification__text">
                    <p>Ваш список замовлень поки порожній. Створіть нового або оформіть страховку або змініть параметри
                        <a href="javascript:void(0);" @click="filterStatusWrap()">фільтра</a> клієнтів.</p>
                </div>
                <a data-toggle="modal" data-target="#modalSearch" class="header__create">Cтворити
                    кліента</a>
            </div>
            <div class="container-wrapp" v-else-if="! isMobile">
                <div class="header-block">
                    <ul class="columns-block">
                        <li class="column">Номер поліса</li>
                        <li class="column">Продукт</li>
                        <li class="column">Страхувальник</li>
                        <li class="column">Дата створення</li>
                        <li class="column">Вартість, грн</li>
                        <li class="column">Заробіток, грн</li>
                    </ul>
                </div>
<!--                <div class="header-block">-->
<!--                    <ul class="columns-block">-->
<!--                        <li class="column">Реферал</li>-->
<!--                        <li class="column">Телефон</li>-->
<!--                        <li class="column">Пошта</li>-->
<!--                        <li class="column">Дата реєстрації</li>-->
<!--                        <li class="column">Статус</li>-->
<!--                    </ul>-->
<!--                </div>-->
                <div v-if="isLoadData">
                    <row-block-skeleton v-for="index in 4" :key="index"></row-block-skeleton>
                </div>
                <div v-else v-for="order in referralOrders" class="row-block orders-row-block">
                    <ul class="columns-block">
                        <li class="column">
                            <div class="column-block">
                                <a  v-if="showMtsbuLink(order)" :href="showMtsbuLink(order)" class="order-number" target="_blank">
                                    {{ showMtsbuCode(order) }}<br />{{ showMtsbuCodeAdditionalInfo(order) }}
                                </a>
                                <span v-else-if="showMtsbuCode(order)" class="order-number">
                                            {{ showMtsbuCode(order) }}
                                        </span>
                                <span class="order-number" v-else>&mdash;</span>

                                <div class="bottom-part order-status">
                                    <div class="bottom-block right-border">
                                        <span>Статус:</span>
                                        <div :class="['status', getStatusClass(order)]"><span>{{
                                                showStatusName(order)
                                            }}</span></div>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li class="column">
                            <div class="column-block">
                                <span class="item-type">{{ showTypeInsurance(order) }}</span>
                                <div class="bottom-part order-company">
                                    <div class="bottom-block right-border">
                                        <span class="option">СК:</span>
                                        <div class="result"
                                             data-toggle="tooltip" data-placement="bottom"
                                             :title="order.companyInfo.name">
                                            <ellipsis
                                                :data="order.companyInfo.name">
                                            </ellipsis>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li class="column">
                            <div class="column-block">
                                <span class="item-type">{{ showNameInsurantSurname(order) }} {{ showNameInsurantName(order) }} {{ showNameInsurantPatronymic(order) }}</span>
<!--                                <span class="item-type">{{ item.email }}</span>-->
                            </div>
                        </li>
                        <li class="column">
                            <div class="column-block">
                                <div>
                                    <span v-if="order.createdAt" class="item-type">{{ order.createdAt }}</span>
                                </div>
                            </div>
                        </li>
                        <li class="column">
                            <div class="column-block">
                                <div class="price-block">
                                    <div class="text">Первісна:</div>
                                    <span class="date">{{ showPrice(order) }}</span>
                                </div>
                                <div v-if="showDiscount(order)"  class="price-block"><!--|| showMonths(order)" class="price-block">-->
                                    <div class="text">Зі знижкою:</div>
                                    <span class="date">{{ showAllPrice(order) }} <i class="icon question" data-html="true" data-placement="bottom" data-select="true" data-toggle="tooltip" title="Первісна вартість може бути знижена за рахунок знижки посередника та за рахунок виключення місяців, з періоду дії полісу."></i></span>
                                </div>
                            </div>
                        </li>
                        <li class="column">
                            <div class="column-block">
                                <div class="price-block">
<!--                                    <div class="text">Ваш:</div>-->
                                    <span v-if="order.referralEarnings" class="price">{{ order.referralEarnings }}</span>
                                    <span v-else class="price">&mdash;</span>
                                </div>
<!--                                <div class="price-block" v-if="order.isPartnerOrder">-->
<!--                                    <div class="text">Агента:</div>-->
<!--                                    <span v-if="order.partnerEarnings" class="price">{{-->
<!--                                            order.partnerEarnings-->
<!--                                        }}</span>-->
<!--                                    <span v-else class="date">&mdash;</span>-->
<!--                                </div>-->
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
            <div v-else>
                <div class="mobile-columns-block">
                    <div v-if="isLoadData">
                        <row-mobile-block-skeleton v-for="index in 4" :key="index"></row-mobile-block-skeleton>
                    </div>
                    <div v-else>
                        <div v-for="order in referralOrders" class="row item-data">
                            <div class="col-7 half-block">
                                <div class="info-block">
                                    <div class="option">Номер поліса</div>
                                    <a  v-if="showMtsbuLink(order)" :href="showMtsbuLink(order)" class="value" target="_blank">
                                        {{ showMtsbuCode(order) }}<br />{{ showMtsbuCodeAdditionalInfo(order) }}
                                    </a>
                                    <span v-else-if="showMtsbuCode(order)" class="value">
                                            {{ showMtsbuCode(order) }}
                                        </span>
                                    <span class="value" v-else>&mdash;</span>
                                </div>
                                <div class="info-block">
                                    <div class="option">Страхувальник</div>
                                    <div class="value">
                                        {{ showNameInsurantSurname(order) }}
                                    </div>
                                    <div class="value">
                                        {{ showNameInsurantName(order) }}
                                    </div>
                                    <div class="value">
                                        {{ showNameInsurantPatronymic(order) }}
                                    </div>
                                </div>
                                <div class="info-block">
                                    <div class="option">Вартість</div>
                                    <div class="sub-options">
                                        <div>
                                            <span class="sub-option">Первісна:</span>
                                            <span class="value">{{ showPrice(order) }}</span>
                                        </div>
                                        <div v-if="showDiscount(order)">
                                            <span class="sub-option">Зі знижкою:</span>
                                            <span class="value">{{ showDiscount(order) }}</span>
                                        </div>
                                        <div v-if="showDgoPrice(order)">
                                            <span class="sub-option">ДЦВ:</span>
                                            <span class="value">{{ showDgoPrice(order) }}</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="info-block">
                                    <div class="sub-options">
                                        <div class="mt-4 mb-1">
                                            <span class="sub-option">Статус:</span>
                                            <div :class="['value status', getStatusClass(order)]"><span>{{
                                                    showStatusName(order)
                                                }}</span></div>
                                        </div>
                                        <div class="d-flex">
                                            <span class="sub-option">СК:</span>
                                            <span class="value">
                                                <ellipsis :data="order.companyInfo.name"></ellipsis>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-5 half-block">
                                <div class="info-block">
                                    <div class="option">Продукт</div>
                                    <div class="value">
                                        {{ showTypeInsurance(order) }}
                                    </div>
                                </div>
                                <div class="info-block">
                                    <div class="option">Дата створення</div>
                                    <div class="value">
                                       <span v-if="order.createdAt" class="item-text">
                                            {{ showDateFormat(order) }}
                                        </span>
                                        <span v-else class="item-number">&mdash;</span>
                                    </div>
                                    <div class="value"></div>
                                    <div class="value"></div>
                                </div>
                                <div class="info-block">
                                    <div class="option">Заробіток, грн</div>
                                    <div class="sub-options">
                                        <div v-if="order.isPartnerOrder">
<!--                                            <span class="sub-option">Загальний:</span>-->
                                            <span v-if="order.referralEarnings" class="value">{{ order.referralEarnings }}</span>
                                            <span v-else class="value">&mdash;</span>
                                        </div>
<!--                                        <div>-->
<!--                                            <span class="sub-option">Ваш:</span>-->
<!--                                            <span v-if="order.myEarnings" class="value">{{ order.myEarnings }}</span>-->
<!--                                            <span v-else class="value">&mdash;</span>-->
<!--                                        </div>-->
<!--                                        <div v-if="order.isPartnerOrder">-->
<!--                                            <span class="sub-option">Агента:</span>-->
<!--                                            <span v-if="order.partnerEarnings" class="value">{{-->
<!--                                                    order.partnerEarnings-->
<!--                                                }}</span>-->
<!--                                            <span v-else class="value">&mdash;</span>-->
<!--                                        </div>-->
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
import Vue from 'vue'
import {orderMixin, customerMixin, HistoryMixin} from '@/mixin'
import {RowBlockSkeleton, RowMobileBlockSkeleton} from './components';

const FileDownload = require('js-file-download');

export default {
    name: 'ReferralOrdersList',
    mixins: [orderMixin, customerMixin, HistoryMixin],
    components: {RowBlockSkeleton, RowMobileBlockSkeleton},
    props: {
        isLoadData: {
            type: Boolean,
            default: () => {
                return true
            }
        },
        isMobile: {
            type: Boolean,
            default: () => {
                return true;
            }
        },
        referralOrders: {
            type: Array,
            default: () => {
                return [{
                    id : null,
                    user_id : null,
                    email : null,
                    phone : null,
                    created_at : null,
                  }];
            },
        },
    },

    data: function () {
        return {
            loadMoreOptions: {},
            listQuery: {
                tabId: 2,
            },
            referralTabs: [
                { name: 'Реферальні замовлення', id: 1, type: "draft" },
                { name: 'Реферальні реєстрації', id: 2, type: "overdue" },
            ],
        }
    },
    created() {
        this.$emit('init-list');
    },
    computed: {
        orderTypes: function () {
            return this.$store.getters.ORDER_TYPES
        },
    },
    methods: {
        showDateFormat: function(item) {
            return moment(item.created_at).format("DD-MM-YYYY");
        },
        showClientInfo() {
            this.$refs.clientInfo.handle(true);
        },
        filterStatusWrap: function() {
            return this.$emit('filter-status-wrap');
        },
        showFio(item){
            if(item.fio){
                return item.fio.trim();
            }else if(item.fio_en)
                return (item.fio) ? item.fio.trim() : item.fio_en;
        },
        showNameReferralSurname: function(item) {
            return item?.usersData?.surname || ''
        },
        showNameReferralName: function(item) {
            return item?.usersData.name || ''
        },
        showNameReferralPatronymic: function(item) {
            return item?.usersData.patronymic || ''
        },
        activateTab :function (status) {
            this.$set(this.listQuery, 'tabId', status.id);
        },
    },
    watch: {
    }
}
</script>
<style lang="scss" scoped>

</style>
