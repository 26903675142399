<template>
    <div class="p-0 row">
        <div class="list-referrals container p-0">
            <div v-if="! isLoadData && (referrals && referrals.length < 1)" class="container empty-notification" >
                <img src="~@/assets/images/empty_img.svg" alt="">
                <div class="empty-notification__text">
                    <p>Ваш список рефералів поки порожній. Надішліть запрошення на реєстрацію
                        <a href="javascript:void(0);" @click="filterStatusWrap()">фільтра</a> клієнтів.</p>
                </div>
                <a data-toggle="modal" data-target="#modalSearch" class="header__create">Cтворити
                    кліента</a>
            </div>
            <div class="container-wrapp" v-else-if="! isMobile">
                <div class="header-block">
                    <ul class="columns-block">
                        <li class="column">Реферал</li>
                        <li class="column">Телефон</li>
                        <li class="column">Пошта</li>
                        <li class="column">Дата реєстрації</li>
                        <li class="column">Статус</li>
                    </ul>
                </div>
                <div v-if="isLoadData">
                    <row-block-skeleton v-for="index in 4" :key="index"></row-block-skeleton>
                </div>
                <div v-else v-for="item in referrals" class="row-block">
                    <ul class="columns-block">
                        <li class="column">
                            <div class="column-block">
                                <span class="item-type">{{ showFio(item) }}</span>
                            </div>
                        </li>
                        <li class="column">
                            <div class="column-block">
                                <span class="item-type">{{ item.phone }}</span>
                            </div>
                        </li>
                        <li class="column">
                            <div class="column-block">
                                <span class="item-type">{{ item.email }}</span>
                            </div>
                        </li>
                        <li class="column">
                            <div class="column-block">
                                <div>
                                    <span class="item-type">{{ showDateFormat(item)}}</span>
                                </div>
                            </div>
                        </li>
                        <li class="column">
                            <div class="column-block">
                                <div :class="['status', getStatusClass(item)]"><span>{{
                                        showStatusName(item)
                                    }}</span></div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
            <div v-else>
                <div class="mobile-columns-block">
                    <div v-if="isLoadData">
                        <row-mobile-block-skeleton v-for="index in 4" :key="index"></row-mobile-block-skeleton>
                    </div>
                    <div v-else>
                        <div v-for="item in referrals" class="row item-data">
                            <div class="col-7 half-block">
                                <div class="info-block">
                                    <div class="option">Реферал</div>
<!--                                    <div class="value">{{ showFio(item) }}</div>-->
                                    <div class="value">
                                        {{ showNameReferralSurname(item) }}
                                    </div>
                                    <div class="value">
                                        {{ showNameReferralName(item) }}
                                    </div>
                                    <div class="value">
                                        {{ showNameReferralPatronymic(item) }}
                                    </div>
                                </div>
                                <div class="info-block">
                                    <div class="option">Телефон</div>
                                    <div class="value">
                                       <span v-if="item.phone" class="item-text">
                                            {{ item.phone }}
                                        </span>
                                        <span class="item-number" v-else>&mdash;</span>
                                    </div>
                                </div>
                                <div class="info-block">
                                    <div class="sub-options">
                                        <div class="mt-4 mb-1">
                                            <span class="sub-option">Статус:</span>
                                            <div :class="['value status', getStatusClass(item)]"><span>{{
                                                    showStatusName(item)
                                                }}</span></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-5 half-block">
                                <div class="info-block">
                                    <div class="option">Пошта</div>
                                    <div class="value">
                                        <span v-if="item.email" class="item-text">
                                            {{ item.email }}
                                        </span>
                                        <span class="item-number" v-else>&mdash;</span>
                                    </div>
                                </div>
                                <div class="info-block">
                                    <div class="option">Дата реєстрації</div>
                                    <div class="value">
                                       <span v-if="item.created_at" class="item-text">
                                            {{ showDateFormat(item) }}
                                        </span>
                                        <span v-else class="item-number">&mdash;</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
import Vue from 'vue'

import {RowBlockSkeleton, RowMobileBlockSkeleton} from './components'; //MoreOptions, , ClientInfo
import {deleteClient, getData} from '../../api/client';

const FileDownload = require('js-file-download');

export default {
    name: 'ReferralList',
    mixins: [ ],
    components: {RowBlockSkeleton, RowMobileBlockSkeleton}, //MoreOptions, , ClientInfo, deleteClient
    props: {
        isLoadData: {
            type: Boolean,
            default: () => {
                return true
            }
        },
        isMobile: {
            type: Boolean,
            default: () => {
                return true;
            }
        },
        referrals: {
            type: Array,
            default: () => {
                return [{
                    id : null,
                    user_id : null,
                    email : null,
                    phone : null,
                    created_at : null,
                  }];
            },
        },
    },

    data: function () {
        return {
            loadMoreOptions: {},
            listQuery: {
                tabId: 2,
            },
            referralTabs: [
                { name: 'Реферальні замовлення', id: 1, type: "draft" },
                { name: 'Реферальні реєстрації', id: 2, type: "overdue" },
            ],
        }
    },
    created() {

    },
    computed: {
    },
    methods: {
        showDateFormat: function(item) {
            return moment(item.created_at).format("DD-MM-YYYY");
        },
        showClientInfo() {
            this.$refs.clientInfo.handle(true);
        },
        filterStatusWrap: function() {
            return this.$emit('filter-status-wrap');
        },
        showFio(item){
            if(item.fio){
                return item.fio.trim();
            }else if(item.fio_en)
                return (item.fio) ? item.fio.trim() : item.fio_en;
        },
        showNameReferralSurname: function(item) {
            return item?.usersData?.surname || ''
        },
        showNameReferralName: function(item) {
            return item?.usersData.name || ''
        },
        showNameReferralPatronymic: function(item) {
            return item?.usersData.patronymic || ''
        },
        showStatusName: function(referral) {
            return referral.status == 1 ? 'Успішна реєстрація' : 'Очікує модерації';
        },
        getStatusClass: function(referral) {
            const referralStatus = 'success';
            const referralWaiting = 'waiting';
            return 'status-' + (referral.status == 1 ? referralStatus : referralWaiting);
        },
        activateTab :function (status) {
            this.$set(this.listQuery, 'tabId', status.id);
        },
    },
    watch: {
    }
}
</script>
<style lang="scss" scoped>

</style>
